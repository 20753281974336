var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "section section__inscription" }, [
        _c("div", { staticClass: "breadcrumb" }, [
          _c("div", { staticClass: "container" }, [
            _c("ol", { staticClass: "items" }, [
              _vm._m(0),
              _c("li", { staticClass: "item" }, [
                _vm.stepStatus == "completed"
                  ? _c("span", { staticClass: "breadcrumb-delimiter ms-2" }, [
                      _vm._v("I"),
                    ])
                  : _vm._e(),
                _vm.stepStatus == "completed"
                  ? _c("span", { staticClass: "breadcrumb__current ms-2" }, [
                      _vm._v(
                        "Finalisation inscription étape " +
                          _vm._s(_vm.stepNumber - 1)
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _vm.stepStatus == "validated"
              ? _c("h1", { staticClass: "h1-light mb-4 text-uppercase" }, [
                  _vm._v(" En attente de vos informations "),
                ])
              : _vm._e(),
            _vm.stepStatus == ""
              ? _c("h1", { staticClass: "h1-light mb-4 text-uppercase" }, [
                  _vm._v("Inscription"),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "card card--light p-0" },
              [
                _c("progress-inscription"),
                _c(_vm.currentStep.component, { tag: "component" }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("login-component"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item text-uppercase" }, [
      _c("span", [_vm._v("Mon compte")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }