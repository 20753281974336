<template>
  <div class="p-4">
    <div class="row">
      <ValidationObserver ref="observer">
        <form autocomplete="off" method="">
          <div class="col-md-10 offset-lg-2">
            <div class="mb-3 row">
              <div class="col-lg-3 col-sm-12">
                <label class="label-dark label-lg" for="">Civilité</label>
                <treeselect
                  v-model="gender"
                  v-if="lgBreakpoint"
                  :options="options"
                  :normalizer="normalizer"
                  no-options-text="Aucune option disponible."
                  no-results-text="Aucun résultat trouvé ..."
                  placeholder="Sélectionner ..."
                />
                <select v-else v-model="gender" class="form-select">
                  <option :value="null">Sélectionner ...</option>
                  <option
                    v-for="item in options"
                    :value="item.name"
                    :key="item.key"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="firstname"
                rules="required"
                v-slot="{ errors }"
                class=""
              >
                <div class="col-lg-3 col-sm-12">
                  <atom-input
                    v-model="firstname"
                    label="Prénom"
                    placeholder="Prénom"
                    type="text"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': errors.length,
                    }"
                    label-class="label-dark"
                  />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <span class="text-danger small" v-if="errors.length">
                    {{ errors[0] }}
                  </span>
                </div>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="lastname"
                rules="required"
                v-slot="{ errors }"
                class=""
              >
                <div class="col-lg-3 col-sm-12">
                  <atom-input
                    v-model="lastname"
                    label="Nom"
                    placeholder="Nom"
                    label-class="label-dark"
                    type="text"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': errors.length,
                    }"
                  />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <span class="text-danger small" v-if="errors.length">
                    {{ errors[0] }}
                  </span>
                </div>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
                class=""
              >
                <div class="col-lg-3 col-sm-12">
                  <atom-input
                    v-model="email"
                    label="E-mail"
                    placeholder="E-mail"
                    type="text"
                    :input-class="{
                      'no-select': true,
                      'form-control': true,
                      'is-invalid': errors.length,
                    }"
                    label-class="label-dark"
                    oncopy="return false"
                  />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <span class="text-danger small" v-if="errors.length">
                    {{ errors[0] }}
                  </span>
                </div>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="emailConfirmation"
                rules="required|confirmed:email"
                v-slot="{ errors }"
               
              >
                <div class="col-lg-3 col-sm-12">
                  <atom-input
                    v-model="emailConfirmation"
                    label="Confirmer votre E-mail"
                    placeholder="E-mail"
                    type="text"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': errors.length,
                    }"
                    label-class="label-dark"
                    onpaste="return false"
                  />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <span class="text-danger small" v-if="errors.length">
                    {{ errors[0] }}
                  </span>
                </div>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="password"
                :rules="{
                  required: true,
                  regex:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
                }"
                v-slot="{ errors }"
                class=""
              >
                <div class="col-lg-3 col-sm-12 position-relative">
                  <atom-input
                    v-model="password"
                    label="Mot de passe"
                    :type="inputType"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': errors.length,
                      'postion-relative': true,
                    }"
                    autocomplete="password"
                    label-class="label-dark"
                  />
                  <svg-icon
                    @click.stop.prevent="showPassword()"
                    :icon-class="
                      inputType == 'password' ? 'eye-open' : 'eye-close'
                    "
                    className="eye-open"
                  />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <span class="text-muted small">
                    Votre mot de passe doit comporter au moins huit caractères,
                    au moins une lettre, un chiffre et un caractère spécial
                    (@$!%*#?&.)
                  </span>
                </div>
              </validation-provider>
            </div>
            <div class="row mb-3">
              <div class="col-lg-6 col-sm-12">
                <validation-provider
                  name="accept"
                  :rules="{ required: { allowFalse: false } }"
                  v-slot="{ errors }"
                  class=""
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="accept"
                      type="checkbox"
                      value="value"
                      id="label-1"
                      :class="{
                        'form-check-label label-dark': true,
                        'is-invalid': errors.length,
                      }"
                    />
                    <label
                      :class="{
                        'form-check-label label-dark': true,
                        'is-invalid': errors.length,
                      }"
                      for="label-1"
                    >
                      J'ai lu et j'accepte sans réserve
                      <a
                        :href="link"
                        target="_blank"
                        class="text-decoration-underline"
                        >les conditions générales de vente et du règlement
                        général sur la protection des données (RGPD)</a
                      >
                    </label>
                  </div>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <validation-provider
                  name="accept2"
                  :rules="{ required: { allowFalse: false } }"
                  v-slot="{ errors }"
                  class=""
                >
                  <div class="form-check">
                    <input
                      type="checkbox"
                      value="value"
                      id="label-2"
                      class="form-check-input"
                      v-model="accept2"
                      :class="{
                        'form-check-label label-dark': true,
                        'is-invalid': errors.length,
                      }"
                    /><label for="label-2" class="form-check-label label-dark">
                      J'ai lu et j'accepte sans réserve le
                      <a
                        href="/reglement-interieur/"
                        target="_blank"
                        class="text-decoration-underline"
                        >Règlement intérieur </a
                      >de l'Ecole du Bien Naître.
                    </label></div
                  >
                </validation-provider>
              </div>
            </div>

            <atom-button
              @click="next"
              btn-class="btn btn-primary m-auto "
              type="button"
              title="Créer mon compte"
              :disabled="isNextButtonDisabled"
              :spinner="isNextButtonDisabled"
            />
            <div class="mt-4">
              <p class="small text-decoration-underline">
                *Au moins 8 caractères,<br />
                *Utilisez au moins une lettre, des chiffres et des caractères
                spéciaux (@$!%*#?&.)
              </p>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <error-modal />
      <support-box />
    </div>
  </div>
</template>

<script>
import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core'
import { mapFields } from 'vuex-map-fields'
import { Modal } from 'bootstrap'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect from '@riophae/vue-treeselect'
import AtomCheckbox from '@/components/atoms/AtomCheckbox'
import AtomInput from '@/components/atoms/AtomInput'
import AtomButton from '@/components/atoms/AtomButton'
import ErrorModal from '@/apps/signup/components/modals/ErrorModal.vue'
import SupportBox from '@/components/SupportBox.vue'
export default {
  name: 'AccountInformation',
  components: {
    Treeselect,
    AtomCheckbox,
    AtomInput,
    AtomButton,
    ErrorModal,
    SupportBox,
  },
  setup() {
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const lgBreakpoint = breakpoints.greater('sm')
    return { lgBreakpoint }
  },
  data() {
    return {
      isNextButtonDisabled: false,
      options: [
        {
          key: 'M.',
          name: 'M.',
        },
        {
          key: 'Mme',
          name: 'Mme',
        },
        {
          key: 'Autre',
          name: 'Autre',
        },
      ],
      normalizer(node) {
        return {
          id: node.key,
          label: node.name,
        }
      },
      gender: null,
      firstname: '',
      lastname: '',
      email: '',
      emailConfirmation: '',
      password: '',
      accept: false,
      accept1: false,
      accept2: false,
      link: '',
      inputType: 'password',
    }
  },
  computed: {
    ...mapFields('inscription', [
      'steps',
      'currentStep',
      'isAccountInformationCompleted',
      'errorModal',
    ]),
  },
  created() {
    const support = {
      header: 'Bonjour,',
      content: `Bienvenue à l’Ecole du Bien Naître !
      Je m’appelle Lulu et je suis là pour vous guider...
      Renseignez vos coordonnées pour créer votre compte.`,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
  },
  mounted() {
    this.$nextTick(() => {
      this.errorModal = new Modal(
        document.getElementById('inscription-error-modal'),
        {
          keyboard: false,
        }
      )
    })
    this.link =
      process.env.VUE_APP_URL + process.env.VUE_APP_GENERAL_CONDITIONS_PAGE
  },
  methods: {
    handlePaste(e) {
      console.warn('Pasting is disabled on this input field.')
    },
    showPassword() {
      if (this.password) {
        if (this.inputType == 'text') {
          return (this.inputType = 'password')
        } else {
          let forms = document.getElementsByTagName('form')
          for (let i = 0; i < forms.length; i++) {
            forms[i].addEventListener(
              'invalid',
              (e) => {
                e.preventDefault()
                //Possibly implement your own here.
              },
              true
            )
          }
          return (this.inputType = 'text')
        }
      }
    },
    next() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          // if (this.$refs.observer.errors.email.length) {
          //   let errorData = {
          //     message: 'Email invalid',
          //   }
          //   this.$bus.$emit('error-modal.show', errorData)
          // }
          // if (this.$refs.observer.errors.emailConfirmation.length) {
          //   let errorData = {
          //     message: 'La confirmation Email ne correspond pas.',
          //   }
          //   this.$bus.$emit('error-modal.show', errorData)
          // }
          return
        }
        this.isNextButtonDisabled = true
        let data = {}
        data.gender = this.gender
        data.firstname = this.firstname
        data.lastname = this.lastname
        data.email = this.email
        data.emailConfirmation = this.emailConfirmation
        data.password = this.password
        data.email_verified = true
        data.email_verified_at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        data.step = 1
        this.$store
          .dispatch('app/setUserData', data)
          .then(() => {
            this.steps[0].status = 'validated'
            this.currentStep = this.steps[1]
          })
          .catch((error) => {
            this.$log.warn(error)
            let errorData = {
              message: 'Email déjà enregistré',
            }
            this.$bus.$emit('error-modal.show', errorData)
          })
          .finally(() => {
            this.isNextButtonDisabled = false
          })
      })
    },
  },
}
</script>

<style>
.eye-open {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: black;
  width: 20px;
  height: 11px;
  cursor: pointer;
}
</style>
