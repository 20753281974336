<template>
  <div
    class="card card-login"
    :class="{ show: isCardLoginIsShowing }"
    v-click-outside="close"
  >
    <div class="card-login__header">
      <div class="d-flex justify-content-center flex-column">
        <div class="login-message-alert">{{ loginMessage }}</div>
        <h4
          class="card-login__title mx-auto"
          v-if="
            !lgBreakpoint && (!user || (user && !user.id)) && !isForgotPassword
          "
          >se connecter
        </h4>
      </div>

      <button type="button" class="btn close" @click="toggleCardLogin"></button>
    </div>
    <div
      class="card-body"
      v-if="(!user || (user && !user.id)) && !isForgotPassword"
      v-cloak
    >
      <div class="text-center" v-if="lgBreakpoint">
        <h4 class="card-login__title">se connecter</h4>
      </div>
      <div class="row">
        <ValidationObserver ref="observer">
          <form autocomplete="off" method="" id="form">
            <div class="col-md-12">
              <div class="mb-3 row">
                <validation-provider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                  class="d-lg-flex justify-content-center"
                  mode="lazy"
                >
                  <div class="col-lg-9 col-sm-12">
                    <atom-input
                      id="email"
                      v-model.trim="email"
                      label="E-mail"
                      type="text"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': errors.length,
                      }"
                      label-class="label-light"
                      @change="removeSpases"
                      autocomplete="email"
                    />
                  </div>
                </validation-provider>
              </div>
              <div class="mb-3 row">
                <validation-provider
                  name="password"
                  rules="required"
                  v-slot="{ errors }"
                  class="d-lg-flex justify-content-center"
                  mode="lazy"
                >
                  <div class="col-lg-9 col-sm-12 position-relative">
                    <atom-input
                      v-model="password"
                      label="Mot de passe"
                      :type="inputType"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': errors.length,
                        'postion-relative': true,
                      }"
                      label-class="label-light"
                      autocomplete="password"
                      @keydown.enter.native="login"
                    />

                    <svg-icon
                      @click.stop.prevent="showPassword()"
                      icon-class="eye-open"
                      className="eye-open"
                    />
                  </div>
                </validation-provider>
              </div>
              <div class="row mb-3 d-lg-flex justify-content-center">
                <div class="col-lg-9 col-sm-12 text-center">
                  <atom-button
                    @click="forgotPassword"
                    btn-class="btn btn-link card-login__link"
                    type="button"
                    title="Mot de passe oublié?"
                  />
                </div>
              </div>
              <div class="row mb-3 d-lg-flex justify-content-center">
                <div class="col-lg-9 col-sm-12 text-center">
                  <atom-button
                    @click="login"
                    btn-class="btn btn-dark m-auto w-100"
                    type="button"
                    title="S'identifier"
                    :spinner="isLoading"
                    :disabled="isLoading"
                  />
                </div>
              </div>
              <div
                class="row mb-3 d-lg-flex justify-content-center"
                v-if="!lgBreakpoint"
              >
                <div class="col-lg-9 col-sm-12 text-center">
                  <atom-button
                    @click="singIn"
                    btn-class="btn btn-light m-auto w-100"
                    type="button"
                    title="Créer un compte"
                  />
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="sep" v-if="lgBreakpoint"></div>
      <div class="row mt-4" v-if="lgBreakpoint">
        <div class="col-md-12">
          <div class="row mb-3 d-lg-flex justify-content-center">
            <div class="col-lg-9 col-sm-12 text-center">
              <div class="text-center">
                <h4 class="card-login__title">Inscription</h4>
                <atom-button
                  @click="singIn"
                  btn-class="btn btn-light m-auto w-100"
                  type="button"
                  title="Créer un compte"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="isForgotPassword" v-cloak>
      <div class="text-center">
        <h4 class="card-login__title">Réinitialisez Votre Mot De Passe</h4>
      </div>
      <div class="user-info-text text-white mb-4 text-center">
        Nous vous enverrons par e-mail<br />
        un lien de réinitialisation de mot de passe.
      </div>
      <ValidationObserver ref="forgotPassword">
        <form autocomplete="off" method="">
          <div class="col-md-12">
            <div class="mb-3 row">
              <validation-provider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
                class="d-lg-flex justify-content-center"
                mode="lazy"
              >
                <div class="col-lg-9 col-sm-12">
                  <atom-input
                    v-model.trim="email"
                    label="Email de l’utilisateur"
                    type="text"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': errors.length,
                    }"
                    placeholder="nom@mail.com"
                    label-class="text-dark"
                    @change="removeSpaces"
                  />
                </div>
              </validation-provider>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="row mb-3 d-lg-flex justify-content-center">
            <div class="col-lg-11 col-sm-12 text-center">
              <div class="text-center">
                <atom-button
                  @click="resetPassword"
                  btn-class="btn btn-dark m-auto reset-password"
                  type="button"
                  title="Réinitialiser le mot de passe"
                  :spinner="resetPasswordLoading"
                  :disabled="resetPasswordLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else-if="user || (user && user.id)" v-cloak>
      <div class="text-center">
        <h4 class="card-login__title">Se déconnecter</h4>
        <div class="">
          <div class="user-info-text text-white mb-4">
            Vous êtes connecté en tant que
          </div>
          <div class="user-info-email text-white mb-4" v-if="user">{{
            user.email
          }}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="row mb-3 d-lg-flex justify-content-center">
            <div class="col-lg-9 col-sm-12 text-center">
              <div class="text-center">
                <atom-button
                  @click="goToMyAccount"
                  btn-class="btn btn-light m-auto w-100 mb-3"
                  type="button"
                  title="Mon compte"
                />
                <atom-button
                  @click="logoutAndRedirect"
                  btn-class="btn btn-dark m-auto w-100"
                  type="button"
                  title="Se déconnecter"
                  :spinner="logoutLoading"
                  :disabled="logoutLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useIdle, useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core'
import { mapFields } from 'vuex-map-fields'
import AtomButton from '@/components/atoms/AtomButton'
import AtomInput from '@/components/atoms/AtomInput'
const Swal = require('sweetalert2')
export default {
  name: 'LoginComponent',
  components: {
    AtomButton,
    AtomInput,
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  setup() {
    const { idle, lastActive } = useIdle(15 * 60 * 1000)
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const lgBreakpoint = breakpoints.greater('sm')
    return {
      idle,
      lastActive,
      lgBreakpoint,
    }
  },
  data() {
    return {
      inputType: 'password',
      email: '',
      password: '',
      isForgotPassword: false,
      isLoading: false,
      resetPasswordLoading: false,
      logoutLoading: false,
      Toaster: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      }),
    }
  },
  computed: {
    ...mapFields('app', [
      'isCardLoginIsShowing',
      'user',
      'isAuthenticated',
      'loginMessage',
      'isConnecting',
      'redirectTo',
    ]),
    ...mapFields('inscription', ['steps', 'currentStep']),
    ...mapFields('cart', ['cartData', 'cartTotalItems']),
  },
  watch: {
    async idle(val) {
      if (val && this.isAuthenticated) {
        await this.logout()
        let timerInterval
        Swal.fire({
          title: 'Vous êtes déconnecté de votre espace sécurisé',
          html: 'La page va se recherger dans <strong></strong> secondes.',
          didOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().querySelector('strong').textContent = (
                Swal.getTimerLeft() / 1000
              ).toFixed(0)
            }, 100)
          },
          timer: 5000,
          backdrop: true,
          allowOutsideClick: false,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then(() => {
          window.location = '/'
        })
      }
    },
  },
  mounted() {},
  methods: {
    removeSpases() {
      this.email.trim()
    },
    toggleCardLogin() {
      this.isCardLoginIsShowing = !this.isCardLoginIsShowing
      this.isForgotPassword = false
    },
    removeSpaces() {
      this.email = this.email.trim()
    },
    login() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return
        }
        let data = {}
        data.action = 'login'
        data.email = this.email
        data.password = this.password
        this.isLoading = true
        this.$store
          .dispatch('app/login', data)
          .then((response) => {
            console.log('response login', response?.emailVerified)
            if (response) {
              this.$store.commit('app/SET_LOGIN', true)
              this.isCardLoginIsShowing = false
              console.log('window.location.pathname ', window.location.pathname)
              if (window.location.pathname == '/inscription') {
                window.location = '/'
              } else if (this.redirectTo) {
                window.location = this.redirectTo
              } else {
                this.isConnecting = true
                window.location.reload()
              }
              // }
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response) {
              //   if (error.response.status == 404) {
              this.Toaster.fire({
                icon: 'error',
                title: 'Veuillez vérifier vos informations',
              }).then(() => {
                this.email = ''
                this.password = ''
              })
              //   }
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    showPassword() {
      if (this.password) {
        if (this.inputType == 'text') {
          return (this.inputType = 'password')
        } else {
          let forms = document.getElementsByTagName('form')
          for (let i = 0; i < forms.length; i++) {
            forms[i].addEventListener(
              'invalid',
              (e) => {
                e.preventDefault()
                //Possibly implement your own here.
              },
              true
            )
          }
          return (this.inputType = 'text')
        }
      }
    },
    reset() {
      this.monitor.visible = false
      this.monitor.message = ''
      this.monitor.type = ''
      this.email = ''
      this.password = ''
      this.$refs.observer.reset()
    },
    singIn() {
      this.user = null
      window.location = '/inscription'
    },
    async logout() {
      this.logoutLoading = true
      return this.$store
        .dispatch('app/logout')
        .then(() => {
          this.user = null
          this.cartData = []
          this.isCardLoginIsShowing = false
          this.cartTotalItems = 0
          this.redirectTo = null
          this.loginMessage = null
        })
        .finally(() => {
          this.logoutLoading = false
        })
    },
    async logoutAndRedirect() {
      await this.logout()
      window.location = '/'
    },
    forgotPassword() {
      this.isForgotPassword = true
    },
    resetPassword() {
      this.$refs.forgotPassword.validate().then((success) => {
        if (!success) {
          this.Toaster.fire({
            icon: 'error',
            title: 'Veuillez entrer un email valide',
          }).then(() => {
            this.email = ''
            this.$refs.forgotPassword.reset()
          })
          return
        }
        let appUrl = document.head.querySelector('meta[name="app-url"]').content
        const data = {
          url: `${appUrl}forgot-password`,
          email: this.email,
        }
        this.resetPasswordLoading = true
        this.$store
          .dispatch('app/forgotpassword', data)
          .then(() => {
            this.Toaster.fire({
              icon: 'success',
              title:
                'Un email de validation vous a été envoyé. Merci de vérifier votre boîte de réception.',
            }).then(() => {
              this.email = ''
              this.isForgotPassword = false
            })
          })
          .catch((error) => {
            console.log('forgotpassword error : ', error.response.status)
            if (error.response.status == 421) {
              this.Toaster.fire({
                icon: 'error',
                title: 'Merci de vérifier votre email',
              }).then(() => {
                this.email = ''
                this.password = ''
              })
            } else {
              this.Toaster.fire({
                icon: 'error',
                title: 'Une erreur s’est produite',
              })
            }
          })
          .finally(() => {
            this.resetPasswordLoading = false
          })
      })
    },
    goToMyAccount() {
      this.isCardLoginIsShowing = false
      window.location = '/mon-compte#/mon-compte'
    },
    close() {
      this.isCardLoginIsShowing = false
      this.isForgotPassword = false
    },
  },
}
</script>

<style scoped lang="scss">
.user-info-text {
  color: white;
}

.user-info-email {
  font-weight: bold;
}

.reset-password {
  text-align: center !important;
}

.training {
  &__title {
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__place {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.eye-open {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: black;
  width: 20px;
  height: 11px;
}

.login-message-alert {
  font-size: 14px;
  text-align: center;
  color: white;
}
</style>
