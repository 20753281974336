<template>
  <div>
    <section class="section section__inscription">
      <div class="breadcrumb">
        <div class="container">
          <ol class="items">
            <li class="item text-uppercase">
              <span>Mon compte</span>
            </li>
            <li class="item">
              <span
                v-if="stepStatus == 'completed'"
                class="breadcrumb-delimiter ms-2"
                >I</span
              >
              <span
                v-if="stepStatus == 'completed'"
                class="breadcrumb__current ms-2"
                >Finalisation inscription étape {{ stepNumber - 1 }}</span
              >
              <!-- <span
                v-if="stepStatus == 'validated' &&
                  currentStep.component == 'email-validation'
                  "
                class="breadcrumb-delimiter"
              >I</span>
              <span
                v-if="stepStatus == 'validated' &&
                  currentStep.component == 'email-validation'
                  "
                class="breadcrumb__current text-uppercase"
              >Finalisation inscription</span> -->
            </li>
          </ol>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <!-- <h1
            class="h1-light mb-4 text-uppercase"
            v-if="stepStatus == 'inValidation'"
          >
            Email en attente de validation
          </h1> -->
          <h1
            class="h1-light mb-4 text-uppercase"
            v-if="stepStatus == 'validated'"
          >
            En attente de vos informations
          </h1>
          <!-- <h1
            class="h1-light mb-4 text-uppercase"
            v-if="stepStatus == 'completed' && stepThreeStatus == ''"
          >
            Finalisation Inscription
          </h1>
          <h1
            class="h1-light mb-4"
            v-if="stepThreeStatus == 'completed'"
          >
            Vérification de l’inscription par l’École du Bien Naître
          </h1> -->
          <h1 class="h1-light mb-4 text-uppercase" v-if="stepStatus == ''"
            >Inscription</h1
          >
          <div class="card card--light p-0">
            <progress-inscription />
            <component :is="currentStep.component"></component>
          </div>
        </div>
      </div>
    </section>
    <login-component />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapFields } from 'vuex-map-fields'
import ProgressInscription from '@/apps/signup/components/ProgressInscription.vue'
import One from '@/apps/signup/components/steps/One.vue'
import Two from '@/apps/signup/components/steps/Two.vue'
import Three from '@/apps/signup/components/steps/Three.vue'
import Four from '@/apps/signup/components/steps/Four.vue'
import LoginComponent from '@/components/LoginComponent.vue'

// import Five from '@/apps/signup/components/steps/Five.vue'
export default {
  name: 'Inscription',
  components: {
    ProgressInscription,
    One,
    Two,
    Three,
    Four,
    LoginComponent,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('app', ['user', 'support']),
    ...mapFields('inscription', ['steps', 'currentStep']),
    stepStatus() {
      return this.steps[0].status
    },
    // stepThreeStatus() {
    //   return this.steps[3].status
    // },
    stepNumber() {
      return this.currentStep.number
    },
  },
  created() {},
  mounted() {
    Cookies.set('hide-support', false, { expires: 1 })
    this.support.show = Cookies.get('hide-support')
    this.$store.dispatch('app/getUserAccount').then(() => {
      let step = this.$lodash.get(this.user, 'step', 0)
      if (step == 0) {
        this.currentStep = this.steps[0]
        this.user = null
        return
      }
      if (step == 1) {
        this.steps[0].status = 'completed'
        this.currentStep = this.steps[1]
      } else if (step == 2) {
        this.steps[0].status = 'completed'
        this.steps[1].status = 'completed'
        this.currentStep = this.steps[2]
      } else {
        window.location = '/'
      }
    })
  },
  methods: {},
}
</script>

<style></style>
